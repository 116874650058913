<template>
  <div>
    <div v-if="loading">
      <loading />
    </div>
    <div class="page">
      <div class="page__title">
        {{ getModuleConfig("transfer_page.form_title") }}
      </div>
      <div
        class="page__desc"
        v-html="getModuleConfig('transfer_page.form_desc')"
      ></div>
      <div v-if="getModuleConfig('transfer_page.content')" v-html="getModuleConfig('transfer_page.content')"></div>
      <form class="form">
          <!-- fields -->
          <div
            v-for="(field, index) in fields"
            :key="`field.${index}`"
            class="field"
          >
            <div class="form-group">
              <label
                class="field__label"
                :class="field.required === true ? 'field--required' : null"
              >
                {{ field.title }}
              </label>
              <SharedSelect
                v-if="field.type === 'select'"
                :options="sponsors"
                :class="{ invalid: v$.form[field.field_key].$error }"
                :placeholder="field.placeholder"
                v-model="form.sponsor_uuid"
                :disabled="getModuleConfig('transfer_page.partner_data_source') != 'feversocial'"
              > </SharedSelect>
              <input v-else
                class="field__input"
                type:="number"
                v-model="form.points"
                :class="{ invalid: v$.form[field.field_key].$error }"
                :placeholder="field.placeholder"
                :disabled="field.disabled || getModuleConfig('transfer_page.partner_data_source') != 'feversocial'"
              />
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div v-if="v$.form[field.field_key].$error" class="form__error-msg">
                {{ field.field_key == 'points' ? '輸入的資料有誤或未填寫且必須大於 0': '輸入的資料有誤或未填寫，請確認'}}
              </div>
              
            </div>
          </div>
          <div class="page__button"> 
            <SharedButton
              class="s-btn-outline-primary col-6"
              click="cancel()"
            > 取消 </SharedButton>
            <SharedButton
              class="s-btn-bg-primary col-6"
              @click="submit"
              :disabled="loading || getModuleConfig('transfer_page.partner_data_source') != 'feversocial'"
            > 送出 </SharedButton>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
//import PointPagination from "@/pages/Liff/Point/PointPagination";
import useVuelidate from "@vuelidate/core";
import pointApi from '@/apis/liff/v2/point'
import pointMixin from "@/mixins/liff/point";
import SharedSelect from "@/components/Page/Liff/Shared/Select";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import { required, minValue } from "@vuelidate/validators";
import liff from "@line/liff";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { mapActions } from "vuex";

export default {
  mixins: [pointMixin],
  components: {
    SharedSelect, SharedButton, Loading
  },
  data() {
    return {
      loading: false,
      fields: [],
      sponsors: [],
      form: this.setForm(),
      page: 1,
      totalPage: 1,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.fetchSponsors()
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  validations() {
    let form = {};

    for (let i = 0; i < this.fields.length; i++) {
      let field = this.fields[i];
      form[field.field_key] = field.required === true ? { required } : {};
      if (field.field_key === 'points') {
        form[field.field_key]['minValue'] = minValue(1)
      }
    }
    return { form }
  },
  methods: {
    ...mapActions("liffPoint", [
      "fetchAvailablePoints",
      "fetchExpiringSoonPoints",
    ]),
    cancel() {
      this.form.points = null;
      liff.closeWindow();
    },
    async init() {
      this.fields = this.fetchFields();
    },
    fetchFields() {
      return  this.getModuleConfig("transfer_page.form_fields")
    },
    setForm() {
      let form = {}
      let fields = this.fetchFields()
      fields.forEach((field) => {
        form[field.field_key] = field.value ?? null
      });

      return { ...form };
    },
    async submit() {
      try {
        this.loading = true
        const result = await this.v$.$validate();
        if (!result) {
          return;
        }
        await pointApi.transferPoints(this.form.sponsor_uuid, this.form.points);

        this.$swal
          .fire({ title: "轉移成功", type: "success" })
      } catch (e) {
        console.error(e)
        if (e?.response?.status == 406) {
          this.$swal.fire({
              title: "轉移失敗",
              type: "error",
              text: e.response.data.message ?? null
          })
        } else {
          this.$swal
            .fire({ title: "轉移失敗", type: "error" })
        }
      } finally {
        this.eventCode = this.$route.query.event ?? null;
        this.fetchAvailablePoints({ eventCode: this.eventCode });
        this.fetchExpiringSoonPoints({ eventCode: this.eventCode });
        this.loading = false
      }
    },
    async fetchSponsors() {
      try {
        this.loading = true
        let res = await pointApi.listSponsors();
        let sponsors = res.data?.data ?? [];
        if (!sponsors || !sponsors.length) {
          this.sponsors = [];
          return;
        }

        this.sponsors = sponsors
          .map((sponsor) => ({
            text: sponsor.name,
            value: sponsor.uuid
          }))
      } catch (e) {
        console.error(e)
        /*
        this.$swal
          .fire({ title: "NGO 無法取得", type: "error" })
        */
      } finally {
        this.loading = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/shared/components/_fields.scss';

.page {
  padding: 16px 12px;
  /* margin-bottom: 12px; */
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-bottom: 16px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__button {
    padding: 15px 15px 6px 0px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    position: sticky;
    bottom: 0;
  }
}

.form {
  padding: 20px 10px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}
</style>
